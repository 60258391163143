<template>
  <section
    class="n-section-primary"
    :style="`background-color: var(--c-${vars.backgroundColor});color: ${vars.textColor ? `var(--c-${vars.textColor})` : 'var(--c-text-primary)'}`"
  >
    <div
      class="tw-mb-10 tw-flex tw-flex-col tw-items-start tw-self-start tw-text-start md:tw-items-center md:tw-self-center md:tw-text-center lg:tw-mb-16"
    >
      <small v-if="vars.altTitleText"> {{ vars.altTitleText }}</small>
      <h2>{{ vars.titleText }}</h2>
    </div>
    <div class="lg:tw-w-[75%]">
      <library-form
        v-if="form"
        :form="form"
        version="v2"
        :is-form-name-visible="vars.isFormNameVisibleBool"
        :component="component"
      ></library-form>
    </div>
  </section>
</template>
<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'ContactForm',

  mixins: [ComponentMixin],

  data() {
    return {
      form: null,
    };
  },

  created() {
    this.form = this.parseFormV2(this.vars.contactForm);
  },

  methods: {
    parseForm() {
      return null;
    },
  },
});
</script>
