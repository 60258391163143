<template>
  <section
    class="n-section-primary"
    :style="`background-color: var(--c-${vars.backgroundColor}); color: ${vars.textColor ? `var(--c-${vars.textColor})` : 'var(--c-text-primary)'}`"
  >
    <h2 class="tw-mb-10 tw-max-w-[580px] tw-self-start md:tw-mb-28">{{ vars.titleText }}</h2>

    <!-- Tabs and Content -->
    <div class="tw-flex tw-w-full tw-flex-col tw-gap-x-14 tw-gap-y-4 md:tw-w-full md:tw-flex-row lg:tw-w-[80%]">
      <!-- Tab Titles -->
      <div class="tw-w-full lg:tw-w-1/4">
        <ul>
          <li v-for="(tab, index) in tabs" :key="index" @click="currentTab = tab" class="tw-mb-6 tw-cursor-pointer">
            <h6
              @click="toggleCollapse(index)"
              class="tw-flex tw-items-center tw-justify-between"
              :class="{ 'tw-text-[var(--c-primary)]': currentTab?.title === tab?.title }"
            >
              {{ tab.title }}
            </h6>

            <!-- Mobile View Collapse Content -->
            <div
              class="tw-mt-2 tw-overflow-hidden tw-transition-all tw-duration-500 md:tw-hidden"
              :style="activeTab === index ? { maxHeight: '1000px', opacity: 1 } : { maxHeight: '0px', opacity: 0 }"
            >
              <p>{{ tab.description }}</p>
            </div>
          </li>
        </ul>
      </div>

      <!-- Tab Content for larger screens (md: and up) -->
      <div
        v-if="currentTab"
        class="tw-hidden tw-w-full tw-max-w-[700px] tw-overflow-hidden tw-opacity-100 tw-transition-all tw-duration-500 tw-ease-in-out md:tw-block lg:tw-w-3/4"
      >
        <transition name="fade" mode="out-in">
          <p :key="currentTab.title">{{ currentTab.description }}</p>
        </transition>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';
export default defineNuxtComponent({
  name: 'DescriptionsBox',

  mixins: [ComponentMixin],

  data() {
    return {
      currentTab: null,
      activeTab: null,
    };
  },

  mounted() {
    this.currentTab = this.tabs[0];
    this.activeTab = 0;
  },

  computed: {
    tabs() {
      return this.groupedVariables.tabs;
    },
  },

  methods: {
    toggleCollapse(index) {
      if (this.activeTab === index) {
        this.activeTab = null;
      } else {
        this.activeTab = index;
      }
    },
  },
});
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
