<template>
  <section class="n-section-secondary" :style="`background-color: var(--c-${vars.backgroundColor})`">
    <div v-if="variants.v1 === variant" class="tw-flex tw-w-full tw-flex-col lg:tw-flex-row lg:tw-justify-center">
      <div
        v-for="(count, index) in counts"
        :key="`countsection-count-${index}`"
        class="tw-flex tw-flex-col tw-items-center tw-justify-start tw-break-all tw-py-6 tw-text-center md:tw-py-10 lg:tw-w-full lg:tw-py-20 2xl:tw-py-24"
        ref="countElements"
      >
        <NuxtImg v-if="count.icon" :src="count.icon" class="tw-mb-7 tw-w-16" />
        <div class="tw-flex tw-flex-row tw-items-center tw-text-[var(--c-primary)]">
          <h3 v-if="count.prefix">{{ count.prefix }}</h3>
          <h3 class="odometer tw-whitespace-nowrap">0</h3>
          <h3 v-if="count.suffix">{{ count.suffix }}</h3>
        </div>
        <p class="tw-mx-6 tw-min-h-[48px]">{{ count.description }}</p>
      </div>
    </div>

    <div v-if="variants.v2 === variant" class="tw-flex tw-w-full tw-flex-col tw-items-center tw-justify-center">
      <h2 class="tw-mb-3">{{ vars.titleText }}</h2>
      <div class="tw-flex tw-w-full tw-flex-col lg:tw-flex-row lg:tw-justify-center">
        <div
          v-for="(count, index) in counts"
          :key="`countsection-count-${index}`"
          class="tw-flex tw-flex-col tw-items-center tw-justify-start tw-break-all tw-py-6 tw-text-center md:tw-py-10 lg:tw-w-full lg:tw-py-20 2xl:tw-py-24"
          ref="countElements"
        >
          <NuxtImg v-if="count.icon" :src="count.icon" class="tw-mb-7 tw-w-16" />
          <div class="tw-flex tw-flex-row tw-items-center tw-text-[var(--c-primary)]">
            <h3 v-if="count.prefix">{{ count.prefix }}</h3>
            <h3 class="odometer tw-whitespace-nowrap">0</h3>
            <h3 v-if="count.suffix">{{ count.suffix }}</h3>
          </div>
          <p class="tw-mx-6 tw-min-h-[48px]">{{ count.description }}</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'CountSection',
  mixins: [ComponentMixin],

  data() {
    return {
      variants: {
        v1: 'v1',
        v2: 'v2',
      },
      observers: [] as IntersectionObserver[],
      animatedElements: new Set(),
    };
  },

  computed: {
    counts() {
      return this.groupedVariables.counts;
    },
  },

  mounted() {
    this.$nextTick(() => {
      this.setupIntersectionObservers();
    });
  },

  beforeUnmount() {
    this.observers.forEach((observer) => observer.disconnect());
  },

  methods: {
    setupIntersectionObservers() {
      const options = {
        root: null,
        rootMargin: '0px',
        threshold: 0.5,
      };

      const elements = this.$refs.countElements;
      if (!elements) return;

      (elements as Element[]).forEach((element, index) => {
        const observer = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting && !this.animatedElements.has(index)) {
              this.updateCount(index);
              this.animatedElements.add(index);
              observer.unobserve(entry.target);
            }
          });
        }, options);

        observer.observe(element);
        this.observers.push(observer);
      });
    },

    updateCount(index: number) {
      const count = this.counts[index];
      const element = this.$el.querySelectorAll('.odometer')[index];
      if (element) {
        let startValue = 0;
        const endValue = parseInt(count.title);
        const duration = 2000;
        const stepTime = 50;

        const steps = duration / stepTime;
        const increment = (endValue - startValue) / steps;

        const updateCounter = () => {
          startValue += increment;
          if (startValue <= endValue) {
            element.innerText = Math.floor(startValue).toString();
            requestAnimationFrame(() => setTimeout(updateCounter, stepTime));
          } else {
            element.innerText = endValue.toString();
          }
        };

        updateCounter();
      }
    },
  },
});
</script>
