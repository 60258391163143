<template>
  <section
    class="n-section-secondary tw-items-center tw-justify-center"
    :style="`background-color: var(--c-${vars.backgroundColor}); color: ${vars.textColor ? `var(--c-${vars.textColor})` : 'var(--c-text-primary)'} `"
  >
    <MotionGroup :preset="vars.animationText ? vars.animationText : 'slideVisibleLeft'" :duration="600">
      <NuxtImg v-if="vars.logoImage" :src="vars.logoImage" class="tw-mb-5 tw-w-16 md:tw-w-20" />
      <h2 class="tw-text-center">
        {{ vars.titleText }}
      </h2>
      <div v-if="buttons" class="tw-mt-10 tw-flex tw-flex-row tw-gap-4 xl:tw-mt-16">
        <nuxt-link
          :external="true"
          v-for="(button, index) in buttons"
          :key="index"
          :class="`n-${button.button_type} n-btn tw-mt-2`"
          :to="button.button_link"
        >
          <div class="n-btn-container">
            <div class="n-btn-group">
              <div class="n-btn-t1">{{ button.button_label }}</div>
              <div class="n-btn-t2">{{ button.button_label }}</div>
            </div>
          </div>
        </nuxt-link>
      </div>
    </MotionGroup>
  </section>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'DescriptionBanner',

  mixins: [ComponentMixin],

  computed: {
    buttons() {
      return this.groupedVariables.buttons;
    },
  },
});
</script>
