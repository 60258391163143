<template>
  <section class="tw-h-[100vh]">
    <video
      v-if="isMobileDevice && vars.mobileBackgroundVideo"
      :src="vars.mobileBackgroundVideo"
      class="tw-absolute tw-left-0 tw-top-0 tw-h-full tw-w-full tw-object-cover"
      autoplay
      loop
      muted
      playsinline
    ></video>

    <video
      v-else-if="vars.backgroundVideo"
      :src="vars.backgroundVideo"
      class="tw-absolute tw-left-0 tw-top-0 tw-h-full tw-w-full tw-object-cover"
      autoplay
      loop
      muted
      playsinline
    ></video>
  </section>
</template>
<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'VideoSection',

  mixins: [ComponentMixin],
});
</script>
