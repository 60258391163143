<template>
  <section class="n-section-primary" :style="`background-color: var(--c-${vars.backgroundColor});'`">
    <div class="tw-w-full tw-overflow-hidden">
      <h2 class="text-animation tw-text-start tw-uppercase">
        <span ref="animatedSpan" class="animated-title"
          >{{ vars.part1Text }} &nbsp;{{ vars.part2Text }} <br />{{ vars.part3Text }}
        </span>
      </h2>
    </div>
  </section>
</template>

<script lang="ts">
import { useWindowSize } from '@vueuse/core';
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'ScrollAnimationTextsTheme6',

  mixins: [ComponentMixin],

  mounted() {
    window.addEventListener('scroll', this.revealSpans);
  },

  beforeUnmount() {
    window.removeEventListener('scroll', this.revealSpans);
  },

  methods: {
    revealSpans() {
      const { height } = useWindowSize();
      const animatedSpan = this.$refs.animatedSpan;
      const triggerDistance = height.value * 0.85;
      let distanceFromTop = animatedSpan.offsetTop;
      let parent = animatedSpan.offsetParent;

      while (parent !== null) {
        distanceFromTop += parent.offsetTop;
        parent = parent.offsetParent;
      }

      distanceFromTop -= window.pageYOffset;
      let math = 1 - (Math.max(distanceFromTop, 0) - triggerDistance) / 5;
      math = math < 0 ? 0 : math > 100 ? 100 : math;

      animatedSpan.style.backgroundSize = `${math}% 100%`;
    },
  },
});
</script>

<style scoped>
@keyframes reveal {
  from {
    opacity: 0;
    clip-path: inset(45% 20% 45% 20%);
  }
  to {
    opacity: 1;
    clip-path: inset(0% 0% 0% 0%);
  }
}

.text-animation {
  animation: linear reveal both;
  animation-timeline: view();
  animation-range: entry 25% cover 50%;
}

span {
  font-family: var(--f-primary) !important;
  color: var(--c-secondary-rgba) !important;
  background-clip: text;
  background-repeat: no-repeat;
  background-image: linear-gradient(90deg, var(--c-primary), var(--c-primary));
}
.heading-medium {
  line-height: 1.3;
}
</style>
