<template>
  <section
    v-if="variants.v1 === variant"
    class="tw-h-[45vh] lg:tw-h-[50vh]"
    :style="`background-color: var(--c-${vars.backgroundColor}); color: ${vars.textColor ? `var(--c-${vars.textColor})` : 'var(--c-text-primary)'}`"
  >
    <div class="tw-w-full">
      <div class="tw-absolute tw-inset-0 tw-flex tw-items-end tw-justify-center">
        <MotionGroup :preset="vars.animationText ? vars.animationText : 'slideVisibleLeft'" :duration="700">
          <h1 class="tw-mb-20 lg:tw-mb-24">
            {{ vars.titleText }}
          </h1>
        </MotionGroup>
      </div>
    </div>
  </section>

  <section
    v-if="variants.v2 === variant"
    class="n-section-secondary tw-h-[45vh] lg:tw-h-[50vh]"
    :style="`background-color: var(--c-${vars.backgroundColor}); color: ${vars.textColor ? `var(--c-${vars.textColor})` : 'var(--c-text-primary)'}`"
  >
    <div class="tw-mb-10 tw-flex tw-h-full tw-w-full">
      <div class="tw-flex tw-items-end tw-justify-start">
        <MotionGroup :preset="vars.animationText ? vars.animationText : 'slideVisibleLeft'" :duration="700">
          <h1 class="">
            {{ vars.titleText }}
          </h1>
        </MotionGroup>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'IntroSection',

  mixins: [ComponentMixin],

  data() {
    return {
      variants: {
        v1: 'v1',
        v2: 'v2',
      },
    };
  },
});
</script>
