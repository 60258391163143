<template>
  <section
    class="n-section-tertiary"
    :style="`background-color: var(--c-${vars.backgroundColor});color: ${vars.textColor ? `var(--c-${vars.textColor})` : 'var(--c-text-primary)'};${!vars.isReversedBool ? 'padding-left: calc((100vw - 100%) / 2) !important;' : 'padding-right: calc((100vw - 100%) / 2) !important;'}`"
  >
    <LibraryPopupsVideoPopup
      v-if="vars.isPopupBool && vars.videoUrl"
      :videoUrl="vars.videoUrl"
      v-model:is-visible="isVisible"
      :portrait="vars.isVerticalBool"
    />
    <div
      class="tw-flex tw-w-full tw-max-w-[610px] tw-flex-col-reverse tw-items-center tw-gap-y-8 lg:tw-max-w-none lg:tw-gap-x-24 xl:tw-gap-x-32"
      :class="{ 'lg:tw-flex-row-reverse': vars.isReversedBool, 'lg:tw-flex-row': !vars.isReversedBool }"
    >
      <div class="tw-flex tw-flex-col tw-items-start tw-gap-4 tw-self-end lg:tw-basis-[70%] xl:tw-basis-[90%]">
        <div class="tw-mb-5 tw-flex tw-flex-col tw-gap-3 lg:tw-mb-8 lg:tw-gap-5">
          <h1>{{ vars.titleText }}</h1>
          <span>{{ vars.roleText }}</span>
        </div>
        <div class="tw-flex tw-flex-col tw-gap-3 lg:tw-gap-5">
          <nuxt-link :to="`tel:${vars.phoneText}`" class="n-link tw-flex tw-flex-row tw-gap-2">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="var(--c-primary)" xmlns="http://www.w3.org/2000/svg">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M2.73303 1.5433C3.95003 0.333297 5.95403 0.548297 6.97303 1.9103L8.23503 3.5943C9.06503 4.7023 8.99103 6.2503 8.00603 7.2293L7.76803 7.4673C7.74104 7.56721 7.7383 7.67211 7.76003 7.7733C7.82303 8.1813 8.16403 9.0453 9.59203 10.4653C11.02 11.8853 11.89 12.2253 12.304 12.2893C12.4083 12.3103 12.5161 12.3072 12.619 12.2803L13.027 11.8743C13.903 11.0043 15.247 10.8413 16.331 11.4303L18.241 12.4703C19.878 13.3583 20.291 15.5823 18.951 16.9153L17.53 18.3273C17.082 18.7723 16.48 19.1433 15.746 19.2123C13.936 19.3813 9.71903 19.1653 5.28603 14.7583C1.14903 10.6443 0.355027 7.0563 0.254027 5.2883C0.204027 4.3943 0.626027 3.6383 1.16403 3.1043L2.73303 1.5433ZM5.77303 2.8093C5.26603 2.1323 4.32203 2.0783 3.79003 2.6073L2.22003 4.1673C1.89003 4.4953 1.73203 4.8573 1.75203 5.2033C1.83203 6.6083 2.47203 9.8453 6.34403 13.6953C10.406 17.7333 14.157 17.8543 15.607 17.7183C15.903 17.6913 16.197 17.5373 16.472 17.2643L17.892 15.8513C18.47 15.2773 18.343 14.2313 17.525 13.7873L15.615 12.7483C15.087 12.4623 14.469 12.5563 14.085 12.9383L13.63 13.3913L13.1 12.8593C13.63 13.3913 13.629 13.3923 13.628 13.3923L13.627 13.3943L13.624 13.3973L13.617 13.4033L13.602 13.4173C13.5598 13.4565 13.5143 13.4919 13.466 13.5233C13.386 13.5763 13.28 13.6353 13.147 13.6843C12.877 13.7853 12.519 13.8393 12.077 13.7713C11.21 13.6383 10.061 13.0473 8.53403 11.5293C7.00803 10.0113 6.41203 8.8693 6.27803 8.0033C6.20903 7.5613 6.26403 7.2033 6.36603 6.9333C6.42216 6.78137 6.50254 6.63953 6.60403 6.5133L6.63603 6.4783L6.65003 6.4633L6.65603 6.4573L6.65903 6.4543L6.66103 6.4523L6.94903 6.1663C7.37703 5.7393 7.43703 5.0323 7.03403 4.4933L5.77303 2.8093Z"
                fill="var(--c-primary)"
              />
            </svg>

            <span> {{ vars.phoneText }}</span>
          </nuxt-link>
          <nuxt-link :to="`mailto: ${vars.mailText}`" class="n-link tw-flex tw-flex-row tw-gap-2">
            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M17.1819 4H6.68188C4.47275 4 2.68188 5.79086 2.68188 8V17C2.68188 19.2091 4.47275 21 6.68188 21H17.1819C19.391 21 21.1819 19.2091 21.1819 17V8C21.1819 5.79086 19.391 4 17.1819 4Z"
                stroke="var(--c-primary)"
                stroke-width="1.5"
              />
              <path
                d="M2.729 8.08984L9.934 12.2198C10.5378 12.5702 11.2234 12.7547 11.9215 12.7547C12.6196 12.7547 13.3052 12.5702 13.909 12.2198L21.134 8.08984"
                stroke="var(--c-primary)"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            <span>{{ vars.mailText }}</span>
          </nuxt-link>
        </div>
        <div class="tw-g clap-y-4 tw-mt-5 tw-flex tw-flex-col lg:tw-mt-8">
          <p v-for="(item, index) in descriptions" :key="`infobox-descriptions-${index}`">
            {{ item.description }}
          </p>
        </div>
        <nuxt-link
          :external="true"
          :class="`n-${vars.buttonTypeText} n-btn tw-mt-5 lg:tw-mt-8`"
          :to="vars.buttonLinkUrl"
          v-if="vars.buttonLinkUrl"
        >
          <div class="n-btn-container">
            <div class="n-btn-group">
              <div class="n-btn-t1">{{ vars.buttonLabelText }}</div>
              <div class="n-btn-t2">{{ vars.buttonLabelText }}</div>
            </div>
          </div>
        </nuxt-link>
      </div>
      <div class="tw-w-full" :class="{ 'tw-max-w-[400px]': vars.isVerticalBool }">
        <iframe
          v-if="!vars.isPopupBool && vars.videoUrl"
          class="tw-w-full tw-rounded-[var(--rounded)] tw-object-cover"
          :src="vars.videoUrl"
          :class="{
            'lg:tw-rounded-none lg:tw-rounded-br-[var(--rounded)] lg:tw-rounded-tr-[var(--rounded)]':
              vars.isReversedBool,
            'lg:tw-rounded-none lg:tw-rounded-bl-[var(--rounded)] lg:tw-rounded-tl-[var(--rounded)]':
              !vars.isReversedBool,
          }"
          :style="vars.isVerticalBool ? ' aspect-ratio:5/8;' : ' aspect-ratio: 16/9;'"
          frameborder="0"
          allowfullscreen
        ></iframe>

        <!-- Fotoğraf gösterimi -->
        <div
          class="tw-relative tw-w-full tw-overflow-hidden"
          :class="{
            'lg:tw-rounded-none lg:tw-rounded-br-[var(--rounded)] lg:tw-rounded-tr-[var(--rounded)]':
              vars.isReversedBool,
            'lg:tw-rounded-none lg:tw-rounded-bl-[var(--rounded)] lg:tw-rounded-tl-[var(--rounded)]':
              !vars.isReversedBool,
          }"
        >
          <LibraryImage
            v-if="vars.photoImage && !vars.videoUrl"
            :src="vars.photoImage"
            :w-ratio="vars.isVerticalBool ? 9 : 645"
            :h-ratio="vars.isVerticalBool ? 16 : 430"
          />
        </div>

        <div
          v-if="vars.photoImage && vars.videoUrl && vars.isPopupBool"
          class="tw-relative tw-w-full tw-overflow-hidden tw-rounded-[var(--rounded)]"
          @click="isVisible = true"
          :class="{
            'lg:tw-rounded-none lg:tw-rounded-br-[var(--rounded)] lg:tw-rounded-tr-[var(--rounded)]':
              vars.isReversedBool,
            'lg:tw-rounded-none lg:tw-rounded-bl-[var(--rounded)] lg:tw-rounded-tl-[var(--rounded)]':
              !vars.isReversedBool,
          }"
        >
          <LibraryImage
            :src="vars.photoImage"
            :alt="vars.titleText"
            class="tw-w-full tw-cursor-pointer"
            :w-ratio="vars.isVerticalBool ? 9 : 645"
            :h-ratio="vars.isVerticalBool ? 16 : 430"
          />
          <button class="tw-absolute tw-left-1/2 tw-top-1/2 -tw-translate-x-1/2 -tw-translate-y-1/2">
            <svg width="80" height="80" viewBox="0 0 162 162" xmlns="http://www.w3.org/2000/svg">
              <circle cx="81" cy="81" r="81" fill="white" />
              <polygon points="65,45 115,81 65,117" fill="black" />
            </svg>
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  mixins: [ComponentMixin],

  data() {
    return {
      isVisible: false,
    };
  },

  computed: {
    descriptions() {
      return this.groupedVariables.descriptions;
    },
  },
});
</script>
