<template>
  <div>
    <!-- Variant 1 -->
    <section
      v-if="variants.v1 === variant"
      class="n-section-primary"
      :style="`background-color: var(--c-${vars.backgroundColor});color: ${vars.textColor ? `var(--c-${vars.textColor})` : 'var(--c-text-primary)'}`"
    >
      <div
        class="tw-flex tw-flex-col tw-items-start tw-self-start tw-text-start md:tw-items-center md:tw-self-center md:tw-text-center lg:tw-mb-10"
      >
        <small v-if="vars.altTitleText"> {{ vars.altTitleText }}</small>
        <h2>{{ vars.titleText }}</h2>
      </div>
      <div class="tw-w-full !tw-text-white">
        <Swiper
          :modules="[SwiperPagination]"
          @slideChange="onSlideChange"
          @swiper="onSwiper"
          :pagination="false"
          :slides-per-view="1"
          :space-between="24"
          :center-insufficient-slides="true"
          effect="fade"
          :clickable="true"
          :breakpoints="{
            320: {
              slidesPerView: 2,
            },
            640: {
              slidesPerView: 3,
            },
            1024: {
              slidesPerView: 4,
            },
            1536: {
              slidesPerView: 6,
            },
          }"
          class="tw-w-full"
        >
          <SwiperSlide v-for="(card, index) in cards" :key="index">
            <nuxt-link class="n-link" :to="card.card_link">
              <div
                class="no-hover shine-effect tw-group tw-relative tw-basis-1/3 tw-overflow-hidden tw-transition-all tw-duration-500"
              >
                <LibraryImage :w-ratio="9" :h-ratio="16" :src="card.card_photo" style="border-radius: var(--rounded)" />
                <div
                  class="shine tw-absolute tw-inset-0 tw-bg-black tw-opacity-0 tw-transition-opacity tw-duration-500 group-hover:tw-opacity-30"
                  style="border-radius: var(--rounded)"
                ></div>
                <div
                  class="card-icon-v1 tw-pointer-events-none tw-absolute tw-right-0 tw-top-0 tw-z-20 tw-flex tw-translate-y-full tw-transform tw-flex-col tw-items-center tw-gap-5 tw-overflow-hidden tw-p-4 tw-text-center tw-opacity-0 tw-transition-all tw-duration-500 group-hover:tw-pointer-events-auto group-hover:-tw-translate-y-0 group-hover:tw-opacity-100"
                >
                  <svg viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="tw-w-6 lg:tw-w-8">
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M21.375 21.375H4.125C3.71016 21.375 3.375 21.0398 3.375 20.625V3.375C3.375 2.96016 3.71016 2.625 4.125 2.625H12.5625C12.6656 2.625 12.75 2.70937 12.75 2.8125V4.125C12.75 4.22812 12.6656 4.3125 12.5625 4.3125H5.0625V19.6875H20.4375V12.1875C20.4375 12.0844 20.5219 12 20.625 12H21.9375C22.0406 12 22.125 12.0844 22.125 12.1875V20.625C22.125 21.0398 21.7898 21.375 21.375 21.375ZM18.8173 4.66713L17.5938 3.4437C17.4837 3.33354 17.5493 3.1437 17.704 3.12495L21.9087 2.63276C22.0282 2.6187 22.1313 2.71948 22.1173 2.84135L21.6251 7.04604C21.6063 7.20073 21.4165 7.26635 21.3063 7.1562L20.0782 5.92807L14.0735 11.9328C14.0009 12.0054 13.8813 12.0054 13.8087 11.9328L12.8149 10.939C12.7423 10.8664 12.7423 10.7468 12.8149 10.6742L18.8173 4.66713Z"
                      fill="white"
                    />
                  </svg>
                </div>
              </div>
            </nuxt-link>
          </SwiperSlide>
        </Swiper>
        <div>
          <LibrarySliderPagination
            :swiper="swiper"
            variant="v4"
            :can-paginate-back="canPaginateBack"
            :can-paginate-next="canPaginateNext"
          />
        </div>
      </div>
    </section>

    <!-- Variant 2 -->
    <section
      v-if="variants.v2 === variant"
      class="n-section-tertiary tw-overflow-hidden"
      :style="`background-color: var(--c-${vars.backgroundColor});color: ${vars.textColor ? `var(--c-${vars.textColor})` : 'var(--c-text-primary)'}`"
    >
      <div class="tw-flex tw-flex-col tw-items-center tw-self-center tw-text-center lg:tw-mb-10">
        <h2>{{ vars.titleText }}</h2>
      </div>
      <div class="tw-w-full !tw-text-white">
        <Swiper
          :modules="[SwiperPagination]"
          @slideChange="onSlideChange"
          @swiper="onSwiper"
          :pagination="false"
          :center-insufficient-slides="true"
          :space-between="20"
          effect="fade"
          :loop="true"
          :clickable="true"
          :breakpoints="{
            320: {
              slidesPerView: 2,
            },
            640: {
              slidesPerView: 3,
            },
            1024: {
              slidesPerView: 5,
              spaceBetween: 30,
            },
            1536: {
              slidesPerView: 7,
              spaceBetween: 40,
            },
          }"
          class="tw-w-full lg:tw-w-[115%] xl:tw-w-[125%]"
        >
          <SwiperSlide v-for="(post, index) in instagram_posts?.content_list" :key="index">
            <div
              v-if="post.is_deleted === false && post.is_hidden === false"
              class="no-hover shine-effect tw-group tw-relative tw-basis-1/3 tw-overflow-hidden tw-transition-all tw-duration-500"
            >
              <LibraryImage
                :w-ratio="9"
                :h-ratio="16"
                :src="post.thumbnail_url"
                style="border-radius: var(--rounded)"
              />

              <video
                v-if="post.media_type === 'VIDEO'"
                :src="post.media_url"
                class="tw-absolute tw-inset-0 tw-h-full tw-w-full tw-object-cover tw-opacity-0 tw-transition-opacity tw-duration-500 group-hover:tw-opacity-100"
                style="border-radius: var(--rounded)"
                muted
                loop
                autoplay
                playsinline
              ></video>

              <div
                class="shine tw-absolute tw-inset-0 tw-opacity-0 tw-transition-opacity tw-duration-500 group-hover:tw-opacity-30"
                style="border-radius: var(--rounded)"
              ></div>
              <div
                class="card-icon-v1 tw-pointer-events-none tw-absolute tw-right-0 tw-top-0 tw-z-20 tw-flex tw-translate-y-full tw-transform tw-cursor-pointer tw-flex-col tw-items-center tw-gap-5 tw-overflow-hidden tw-p-4 tw-text-center tw-opacity-0 tw-transition-all tw-duration-500 group-hover:tw-pointer-events-auto group-hover:-tw-translate-y-0 group-hover:tw-opacity-100"
                @click="openPopup(post, true)"
              >
                <svg viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="tw-w-6 lg:tw-w-8">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M21.375 21.375H4.125C3.71016 21.375 3.375 21.0398 3.375 20.625V3.375C3.375 2.96016 3.71016 2.625 4.125 2.625H12.5625C12.6656 2.625 12.75 2.70937 12.75 2.8125V4.125C12.75 4.22812 12.6656 4.3125 12.5625 4.3125H5.0625V19.6875H20.4375V12.1875C20.4375 12.0844 20.5219 12 20.625 12H21.9375C22.0406 12 22.125 12.0844 22.125 12.1875V20.625C22.125 21.0398 21.7898 21.375 21.375 21.375ZM18.8173 4.66713L17.5938 3.4437C17.4837 3.33354 17.5493 3.1437 17.704 3.12495L21.9087 2.63276C22.0282 2.6187 22.1313 2.71948 22.1173 2.84135L21.6251 7.04604C21.6063 7.20073 21.4165 7.26635 21.3063 7.1562L20.0782 5.92807L14.0735 11.9328C14.0009 12.0054 13.8813 12.0054 13.8087 11.9328L12.8149 10.939C12.7423 10.8664 12.7423 10.7468 12.8149 10.6742L18.8173 4.66713Z"
                    fill="white"
                  />
                </svg>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
        <div>
          <LibrarySliderPagination
            :swiper="swiper"
            variant="v5"
            :can-paginate-back="canPaginateBack"
            :can-paginate-next="canPaginateNext"
          />
        </div>
      </div>
    </section>
    <MotionGroup :preset="vars.animationText ? vars.animationText : 'popVisible'" :duration="400">
      <popup v-if="selectedPost && popupConfig.isActive" :config="popupConfig" custom-width="60%">
        <template #content>
          <div class="popup-container tw-grid tw-grid-cols-12 max-lg:tw-pt-6 lg:tw-overflow-y-hidden">
            <!-- Video Section -->
            <div class="video-section tw-relative tw-col-span-12 lg:tw-col-span-5">
              <div class="tw-aspect-[9/16] tw-h-full tw-w-full">
                <video
                  v-if="selectedPost.media_type?.toLowerCase() === 'video'"
                  :src="selectedPost.media_url"
                  class="tw-absolute tw-inset-0 tw-h-full tw-w-full tw-object-cover"
                  style="border-radius: var(--rounded)"
                  muted
                  autoplay
                  loop
                  playsinline
                  controls
                ></video>
                <img
                  v-else
                  :src="selectedPost.thumbnail_url"
                  alt="Post thumbnail"
                  class="tw-absolute tw-inset-0 tw-h-full tw-w-full tw-object-cover"
                />
              </div>
            </div>

            <!-- Caption Section -->
            <div
              class="tw-col-span-12 tw-flex tw-flex-col tw-overflow-y-auto max-lg:tw-mt-4 lg:tw-col-span-5 lg:tw-p-8"
            >
              <div class="tw-flex tw-flex-col">
                <div class="tw-w-24">
                  <LibraryImage :src="company.light_logo" :h-ratio="1" :w-ratio="1.2" />
                </div>
                <!-- <h4>{{ company.name }}</h4> -->
              </div>
              <br />
              <p class="tw-mb-4">{{ selectedPost.caption }}</p>
              <nuxt-link :to="selectedPost.permalink" target="_blank" class="n-link tw-cursor-pointer">
                <svg
                  fill="var(--c-primary)"
                  version="1.1"
                  id="Layer_1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  width="28px"
                  height="28px"
                  viewBox="0 0 169.063 169.063"
                  xml:space="preserve"
                >
                  <g>
                    <path
                      d="M122.406,0H46.654C20.929,0,0,20.93,0,46.655v75.752c0,25.726,20.929,46.655,46.654,46.655h75.752
		c25.727,0,46.656-20.93,46.656-46.655V46.655C169.063,20.93,148.133,0,122.406,0z M154.063,122.407
		c0,17.455-14.201,31.655-31.656,31.655H46.654C29.2,154.063,15,139.862,15,122.407V46.655C15,29.201,29.2,15,46.654,15h75.752
		c17.455,0,31.656,14.201,31.656,31.655V122.407z"
                    />
                    <path
                      d="M84.531,40.97c-24.021,0-43.563,19.542-43.563,43.563c0,24.02,19.542,43.561,43.563,43.561s43.563-19.541,43.563-43.561
		C128.094,60.512,108.552,40.97,84.531,40.97z M84.531,113.093c-15.749,0-28.563-12.812-28.563-28.561
		c0-15.75,12.813-28.563,28.563-28.563s28.563,12.813,28.563,28.563C113.094,100.281,100.28,113.093,84.531,113.093z"
                    />
                    <path
                      d="M129.921,28.251c-2.89,0-5.729,1.17-7.77,3.22c-2.051,2.04-3.23,4.88-3.23,7.78c0,2.891,1.18,5.73,3.23,7.78
		c2.04,2.04,4.88,3.22,7.77,3.22c2.9,0,5.73-1.18,7.78-3.22c2.05-2.05,3.22-4.89,3.22-7.78c0-2.9-1.17-5.74-3.22-7.78
		C135.661,29.421,132.821,28.251,129.921,28.251z"
                    />
                  </g>
                </svg>
              </nuxt-link>
            </div>
          </div>
        </template>
      </popup>
    </MotionGroup>
  </div>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';
import Popup from '~/components/common/popup/Popup.vue';

export default defineNuxtComponent({
  components: { Popup },

  mixins: [ComponentMixin],

  data() {
    return {
      selectedPost: null,
      swiper: null,
      canPaginateBack: true,
      canPaginateNext: true,
      variants: {
        v1: 'v1',
        v2: 'v2',
      },
      popupConfig: {
        isActive: false,
      },
      isVisible: false,
      instagram_posts: null,
    };
  },

  methods: {
    openPopup(post = null, value = false) {
      if (post) {
        this.selectedPost = post;
        this.popupConfig.isActive = value;
      }
    },

    onSwiper(swiper) {
      this.swiper = swiper;
    },

    onSlideChange() {
      if (this.variant === 'v1') {
        if (!this.swiper) return;
        const isAtStart = this.swiper.isBeginning;
        const isAtEnd = this.swiper.isEnd;
        if (isAtStart) this.canPaginateBack = false;
        else this.canPaginateBack = true;
        if (isAtEnd) this.canPaginateNext = false;
        else this.canPaginateNext = true;
      } else if (this.variant === 'v2') {
        if (!this.swiper) return;
        this.canPaginateBack = true;
        this.canPaginateNext = true;
      }
    },
  },
  created() {
    this.instagram_posts = this.parseFormV2(this.vars.socialInstagramPosts);
  },
  mounted() {
    this.onSlideChange();
  },
});
</script>

<style scoped>
@media (hover: none) {
  .no-hover .shine-effect {
    filter: none;
  }

  .no-hover .shine {
    opacity: 20%;
  }

  .no-hover .card-icon-v1 {
    opacity: 100%;
    pointer-events: auto;
    transform: translateY(0px);
  }
}

@media (min-width: 700px) and (max-width: 1400px) {
  ::v-deep(.pm-modal-wrapper) {
    width: 95% !important;
  }
}

@media (min-width: 1401px) {
  ::v-deep(.pm-modal-wrapper) {
    width: 60% !important;
  }
}
</style>
