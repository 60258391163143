<template>
  <section
    v-if="variants.v1 === variant"
    class="n-section-secondary"
    :style="`background-color: var(--c-${vars.backgroundColor})`"
  >
    <h2 v-if="vars.titleText" class="tw-mb-12">{{ vars.titleText }}</h2>

    <div class="tw-w-full">
      <neuron-google-map-v2
        v-if="mapToDisplay && mapToDisplay.length"
        v-model:markers="mapToDisplay"
        :center="mapToDisplay[0]"
        :markerImg="mapToDisplay[0].marker"
      ></neuron-google-map-v2>
    </div>
  </section>

  <section
    v-if="variants.v2 === variant"
    class="n-section-secondary !tw-py-0"
    :style="`background-color: var(--c-${vars.backgroundColor})`"
  >
    <div class="tw-w-screen">
      <neuron-google-map-v2
        v-if="mapToDisplay"
        v-model:markers="mapToDisplay"
        :center="mapToDisplay[0]"
        :markerImg="mapToDisplay[0].marker"
      ></neuron-google-map-v2>
    </div>
  </section>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';
import NeuronGoogleMapV2 from '~/components/common/NeuronGoogleMapV2.vue';

export default defineNuxtComponent({
  name: 'BranchLocation',

  mixins: [ComponentMixin],

  components: { NeuronGoogleMapV2 },

  data() {
    return {
      selectedBranch: 0,
      variants: {
        v1: 'v1',
        v2: 'v2',
      },
    };
  },

  computed: {
    branches() {
      return this.groupedVariables.branches;
    },

    maps() {
      return this.groupedVariables.locations;
    },

    mapToDisplay() {
      return [
        {
          branch_id: this.vars.branchId,
          lat: this.vars.locationLatNumber,
          lng: this.vars.locationLngNumber,
          marker: this.vars.locationMarkerImage,
          version: this.vars.mapVersionText,
          branchName: this.vars.branchNameText,
        },
      ];
    },
  },
});
</script>
