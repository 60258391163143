<template>
  <section
    v-if="variants.v1 === variant"
    class="n-section-secondary"
    :style="`background-color: var(--c-${vars.backgroundColor}); color: ${vars.textColor ? `var(--c-${vars.textColor})` : 'var(--c-text-primary)'}`"
  >
    <div class="tw-flex tw-h-full tw-w-full tw-items-center tw-justify-center tw-text-center">
      <NuxtImg
        v-if="vars.backgroundImage"
        :src="vars.backgroundImage"
        class="tw-absolute tw-inset-0 tw-h-full tw-w-full tw-object-cover tw-object-center"
      />
      <div
        class="tw-absolute tw-inset-0 tw-opacity-50"
        :style="`background-color: var(--c-${vars.backgroundLayerColor}); `"
      ></div>
      <div
        class="tw-relative tw-max-w-2xl tw-px-2 tw-py-20 tw-text-center"
        :style="`color: ${vars.textColor ? `var(--c-${vars.textColor})` : 'var(--c-text-primary)'}`"
      >
        <MotionGroup :preset="vars.animationText ? vars.animationText : 'fadeVisible'" :duration="800">
          <div class="tw-mb-10 tw-flex tw-flex-col tw-gap-3 tw-self-center tw-text-center">
            <small v-if="vars.altTitleText"> {{ vars.altTitleText }}</small>
            <h2>{{ vars.titleText }}</h2>
          </div>
          <p>
            {{ vars.descriptionText }}
          </p>
          <div
            v-if="buttons"
            class="tw-mt-10 tw-flex tw-items-center tw-justify-center tw-gap-16 tw-whitespace-nowrap md:tw-flex-row md:tw-px-14"
          >
            <nuxt-link
              v-for="(button, index) in buttons"
              :external="true"
              :key="index"
              :class="`n-${button.type} n-btn tw-mt-2`"
              :to="button.link"
            >
              <div class="n-btn-container">
                <div class="n-btn-group">
                  <div class="n-btn-t1">{{ button.label }}</div>
                  <div class="n-btn-t2">{{ button.label }}</div>
                </div>
              </div>
            </nuxt-link>
          </div>
        </MotionGroup>
      </div>
    </div>
  </section>

  <section
    v-if="variants.v2 === variant"
    class="n-section-primary"
    :style="`background-color: var(--c-${vars.backgroundColor}); color: ${vars.textColor ? `var(--c-${vars.textColor})` : 'var(--c-text-primary)'}`"
  >
    <div class="tw-flex tw-h-full tw-w-full tw-items-center tw-justify-center tw-text-center">
      <NuxtImg
        v-if="vars.backgroundImage"
        :src="vars.backgroundImage"
        class="tw-absolute tw-inset-0 tw-h-full tw-w-full tw-object-cover tw-object-center"
      />
      <div
        class="tw-absolute tw-inset-0 tw-opacity-50"
        :style="`background-color: var(--c-${vars.backgroundLayerColor}); `"
      ></div>
      <div
        class="tw-relative tw-max-w-3xl tw-px-2 tw-text-center"
        :style="`color: ${vars.textColor ? `var(--c-${vars.textColor})` : 'var(--c-text-primary)'}`"
      >
        <MotionGroup :preset="vars.animationText ? vars.animationText : 'fadeVisible'" :duration="800">
          <div class="tw-mb-10 tw-flex tw-flex-col tw-items-center tw-gap-3 tw-self-center tw-text-center">
            <NuxtImg v-if="vars.logoImage" :src="vars.logoImage" class="tw-mb-5 tw-w-16 md:tw-w-20" />
            <h2>{{ vars.titleText }}</h2>
          </div>
          <p>
            {{ vars.descriptionText }}
          </p>
          <div
            v-if="buttons"
            class="tw-mt-10 tw-flex tw-items-center tw-justify-center tw-gap-16 tw-whitespace-nowrap md:tw-flex-row md:tw-px-14"
          >
            <nuxt-link
              v-for="(button, index) in buttons"
              :key="index"
              :class="`n-${button.type} n-btn tw-mt-2`"
              :to="button.link"
              :external="true"
            >
              <div class="n-btn-container">
                <div class="n-btn-group">
                  <div class="n-btn-t1">{{ button.label }}</div>
                  <div class="n-btn-t2">{{ button.label }}</div>
                </div>
              </div>
            </nuxt-link>
          </div>
        </MotionGroup>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';
export default defineNuxtComponent({
  name: 'ContentSection',

  mixins: [ComponentMixin],

  data() {
    return {
      variants: {
        v1: 'v1',
        v2: 'v2',
      },
    };
  },
  computed: {
    buttons() {
      return this.groupedVariables.buttons;
    },
  },
});
</script>
