<template>
  <section
    class="n-section-primary"
    :style="`background-color: var(--c-${vars.backgroundColor});color: ${vars.textColor ? `var(--c-${vars.textColor})` : 'var(--c-text-primary)'}`"
  >
    <LibraryPopupsVideoPopup
      v-if="vars.isPopupBool && vars.videoUrl"
      :videoUrl="vars.videoUrl"
      v-model:is-visible="isVisible"
    />
    <h2 class="tw-mb-10 md:tw-mb-16">{{ vars.titleText }}</h2>
    <div class="tw-flex tw-w-full tw-flex-col tw-items-center tw-justify-center">
      <!-- Photo Only -->
      <div class="tw-relative tw-w-full tw-overflow-hidden">
        <LibraryImage
          v-if="vars.photoImage && !vars.videoUrl"
          :src="vars.photoImage"
          :h-ratio="0.6"
          :w-ratio="1.5"
          :sm-h-ratio="6.6"
          :sm-w-ratio="4"
        />
      </div>

      <!-- Inline Video -->
      <iframe
        v-if="!vars.isPopupBool && vars.videoUrl"
        class="aspe tw-aspect-[4/6.6] tw-w-full tw-max-w-md tw-rounded-[var(--rounded)] tw-object-cover md:tw-aspect-[15/6] md:tw-max-w-none"
        :src="vars.videoUrl"
        frameborder="0"
        allowfullscreen
      ></iframe>

      <!-- Popup Video -->
      <div
        v-if="vars.photoImage && vars.videoUrl && vars.isPopupBool"
        class="tw-fl tw-relative tw-w-full tw-max-w-md tw-overflow-hidden tw-rounded-[var(--rounded)] md:tw-max-w-none"
        @click="isVisible = true"
      >
        <LibraryImage
          :src="vars.photoImage"
          :alt="vars.titleText"
          :h-ratio="0.6"
          :w-ratio="1.5"
          :sm-h-ratio="6.6"
          :sm-w-ratio="4"
          class="tw-w-full tw-cursor-pointer"
        />
        <button class="tw-absolute tw-left-1/2 tw-top-1/2 -tw-translate-x-1/2 -tw-translate-y-1/2">
          <svg width="48" height="48" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M18 0C22.7739 0 27.3523 1.8722 30.7279 5.20473C34.1036 8.53726 36 13.0571 36 17.7701C36 22.483 34.1036 27.0029 30.7279 30.3354C27.3523 33.6679 22.7739 35.5401 18 35.5401C13.2261 35.5401 8.64773 33.6679 5.27208 30.3354C1.89642 27.0029 0 22.483 0 17.7701C0 13.0571 1.89642 8.53726 5.27208 5.20473C8.64773 1.8722 13.2261 0 18 0ZM3.375 17.7701C3.375 21.5993 4.91584 25.2717 7.65856 27.9794C10.4013 30.6871 14.1212 32.2082 18 32.2082C21.8788 32.2082 25.5987 30.6871 28.3414 27.9794C31.0842 25.2717 32.625 21.5993 32.625 17.7701C32.625 13.9408 31.0842 10.2684 28.3414 7.56073C25.5987 4.85305 21.8788 3.33189 18 3.33189C14.1212 3.33189 10.4013 4.85305 7.65856 7.56073C4.91584 10.2684 3.375 13.9408 3.375 17.7701ZM14.3528 11.6105L23.9467 17.2947C24.0297 17.3441 24.0982 17.4139 24.1458 17.4972C24.1934 17.5804 24.2185 17.6744 24.2185 17.7701C24.2185 17.8657 24.1934 17.9597 24.1458 18.043C24.0982 18.1262 24.0297 18.196 23.9467 18.2454L14.3528 23.9296C14.2674 23.9803 14.17 24.0078 14.0704 24.0092C13.9708 24.0106 13.8727 23.9858 13.7859 23.9375C13.6992 23.8891 13.627 23.8189 13.5768 23.734C13.5266 23.6491 13.5001 23.5526 13.5 23.4543V12.0881C13.4997 11.9896 13.5259 11.8927 13.5759 11.8075C13.626 11.7223 13.6982 11.6518 13.7849 11.6032C13.8717 11.5546 13.9701 11.5296 14.0699 11.5309C14.1696 11.5322 14.2673 11.5597 14.3528 11.6105Z"
              fill="white"
            />
          </svg>
        </button>
      </div>
    </div>
  </section>
</template>
<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'VideoBox',

  mixins: [ComponentMixin],

  data() {
    return {
      isVisible: false,
    };
  },
});
</script>
