<template>
  <lazy-theme6-base-not-found
    :class="['tw-flex', { 'tw-items-center tw-justify-center tw-text-center': vars.isTextCenterBool }]"
    :is-full-screen-error="true"
    :background-color="vars.backgroundColor"
  >
    <template #title>
      <div
        v-if="variant === variants.v1"
        :class="{
          'tw-flex tw-flex-col tw-items-center tw-justify-center tw-text-center': vars.isTextCenterBool === true,
        }"
      >
        <h2 class="tw-pb-6 !tw-text-[56px] lg:tw-max-w-[60%]">
          {{ vars.titleText }}
        </h2>

        <div v-if="descriptions">
          <p v-for="(item, index) in descriptions" :key="`notfound-descriptions-${index}`">
            {{ item.description }}
          </p>
        </div>

        <div v-if="buttons" class="tw-pt-5">
          <nuxt-link
            v-for="(button, index) in buttons"
            :key="index"
            :class="`n-${button.type} n-btn tw-mt-2 max-lg:tw-mb-6 max-lg:tw-flex max-lg:tw-flex-col lg:tw-mr-6`"
            :to="button.link"
          >
            <div class="n-btn-container">
              <div class="n-btn-group">
                <div class="n-btn-t1">{{ button.label }}</div>
                <div class="n-btn-t2">{{ button.label }}</div>
              </div>
            </div>
          </nuxt-link>
        </div>
      </div>
      <div
        v-else-if="variant === variants.v2"
        :class="{
          'tw-flex tw-flex-col tw-items-center tw-justify-center tw-text-center': vars.isTextCenterBool === true,
        }"
      >
        <h2 class="tw-pb-6 !tw-text-[56px] lg:tw-max-w-[70%]">
          {{ vars.titleText }}
        </h2>
        <div v-if="buttons" class="tw-pt-5">
          <nuxt-link
            v-for="(button, index) in buttons"
            :key="index"
            :class="`n-${button.type} n-btn tw-mt-2 max-lg:tw-mb-6 max-lg:tw-flex max-lg:tw-flex-col lg:tw-mr-6`"
            :to="button.link"
          >
            <div class="n-btn-container">
              <div class="n-btn-group">
                <div class="n-btn-t1">{{ button.label }}</div>
                <div class="n-btn-t2">{{ button.label }}</div>
              </div>
            </div>
          </nuxt-link>
        </div>
      </div>
    </template>
  </lazy-theme6-base-not-found>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'NotFoundPage',

  mixins: [ComponentMixin],

  setup() {
    const event = useRequestEvent();

    // event will be undefined in the browser
    if (event) {
      // Set the status message as well
      setResponseStatus(event, 404, 'Page Not Found');
    }
  },

  data() {
    return {
      variants: {
        v1: 'v1',
        v2: 'v2',
      },
    };
  },

  computed: {
    descriptions() {
      return this.groupedVariables.descriptions;
    },
    buttons() {
      return this.groupedVariables.buttons;
    },
  },
});
</script>
<style scoped>
::v-deep(h1) {
  font-size: 180px !important;
  padding-top: 16px;
}
</style>
