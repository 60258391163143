<template>
  <section class="tw-h-[100vh]" v-if="variants.v1 === variant">
    <video
      v-if="isMobileDevice && vars.mobileBackgroundVideo"
      :src="vars.mobileBackgroundVideo"
      class="tw-absolute tw-left-0 tw-top-0 tw-h-full tw-w-full tw-object-cover"
      autoplay
      loop
      muted
      playsinline
    ></video>

    <video
      v-else-if="vars.backgroundVideo"
      :src="vars.backgroundVideo"
      class="tw-absolute tw-left-0 tw-top-0 tw-h-full tw-w-full tw-object-cover"
      autoplay
      loop
      muted
      playsinline
    ></video>
    <div class="tw-absolute tw-inset-0 tw-bg-black tw-opacity-25"></div>
    <div class="tw-relative tw-z-10 tw-flex tw-h-full tw-w-full tw-items-end tw-justify-center">
      <div class="tw-z-100 tw-mx-2 tw-my-4 tw-mb-8 tw-flex tw-flex-col tw-gap-2 tw-text-center xl:tw-gap-6">
        <h1 class="wow fadeInUp tw-mb-2 tw-text-white" data-delay=".4s">
          {{ vars.titleText }}
        </h1>
        <div
          class="wow fadeInUp tw-m-5 tw-mt-0 tw-flex tw-flex-wrap tw-items-center tw-justify-center tw-gap-3 md:tw-m-10 md:tw-mt-0 lg:tw-gap-5"
          data-delay=".4s"
        >
          <nuxt-link
            v-for="(button, index) in buttons"
            :key="`herosection-button-${index}`"
            :class="`n-${button.type} n-btn !tw-text-white`"
            :to="button.link"
            :external="true"
          >
            <div class="n-btn-container">
              <div class="n-btn-group">
                <div class="n-btn-t1">{{ button.label }}</div>
                <div class="n-btn-t2">{{ button.label }}</div>
              </div>
            </div>
          </nuxt-link>
        </div>
      </div>
    </div>
  </section>
  <section class="tw-h-[100vh]" v-if="variants.v2 === variant">
    <video
      v-if="isMobileDevice && vars.mobileBackgroundVideo"
      :src="vars.mobileBackgroundVideo"
      class="tw-absolute tw-left-0 tw-top-0 tw-h-full tw-w-full tw-object-cover"
      autoplay
      loop
      muted
      playsinline
    ></video>

    <video
      v-else-if="vars.backgroundVideo"
      :src="vars.backgroundVideo"
      class="tw-absolute tw-left-0 tw-top-0 tw-h-full tw-w-full tw-object-cover"
      autoplay
      loop
      muted
      playsinline
    ></video>
    <img
      v-if="vars.mobileBackgroundImage && isMobileDevice"
      :src="vars.mobileBackgroundImage"
      alt="Background"
      class="tw-absolute tw-left-0 tw-top-0 tw-h-full tw-w-full tw-object-cover"
    />
    <img
      v-else-if="vars.backgroundImage"
      :src="vars.backgroundImage"
      alt="Background"
      class="tw-absolute tw-left-0 tw-top-0 tw-h-full tw-w-full tw-object-cover"
    />
    <div class="tw-absolute tw-inset-0 tw-bg-black tw-opacity-25"></div>
    <div class="tw-relative tw-z-10 tw-flex tw-h-full tw-w-full tw-items-end tw-justify-start">
      <div
        class="tw-z-100 tw-my-4 tw-mb-8 tw-flex tw-flex-col tw-gap-2 tw-px-3 tw-py-2 tw-text-start md:tw-px-14 md:tw-py-10 xl:tw-w-[90%] xl:tw-gap-6 xl:tw-px-10"
      >
        <h1 class="wow fadeInUp tw-mb-2 tw-text-white" data-delay=".4s">
          {{ vars.titleText }}
        </h1>
        <div
          class="wow fadeInUp tw-flex tw-flex-wrap tw-items-center tw-justify-start tw-gap-3 min-[430px]:tw-flex-nowrap"
          data-delay=".4s"
        >
          <nuxt-link
            v-for="(button, index) in buttons"
            :key="`herosection-button-${index}`"
            :class="`n-${button.type} n-btn`"
            :to="button.link"
            :external="true"
          >
            <div class="n-btn-container">
              <div class="n-btn-group">
                <div class="n-btn-t1">{{ button.label }}</div>
                <div class="n-btn-t2">{{ button.label }}</div>
              </div>
            </div>
          </nuxt-link>
        </div>
        <div class="banner-scroll-down tw-hidden lg:tw-block">
          <a class="section-link" @click="scrollToNextSection">
            <span></span>
            <span></span>
            <span></span>
          </a>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';
export default defineNuxtComponent({
  name: 'HeroSection',

  mixins: [ComponentMixin],

  data() {
    return {
      variants: {
        v1: 'v1',
        v2: 'v2',
      },
    };
  },

  computed: {
    buttons() {
      return this.groupedVariables.buttons;
    },
  },

  methods: {
    scrollToNextSection() {
      const start = window.scrollY;
      const target = start + window.innerHeight * 0.98;
      const duration = 700;
      const startTime = performance.now();

      const animateScroll = (currentTime) => {
        const elapsedTime = currentTime - startTime;
        const progress = Math.min(elapsedTime / duration, 1);
        const ease = progress * (2 - progress);

        window.scrollTo(0, start + (target - start) * ease);

        if (progress < 1) {
          requestAnimationFrame(animateScroll);
        }
      };

      requestAnimationFrame(animateScroll);
    },
  },
});
</script>

<style scoped>
.banner-scroll-down {
  position: absolute;
  left: 90%;
  transform: translateX(-50%);
  bottom: 80px;
}
.banner-scroll-down a span {
  border-color: var(--white);
}

.banner-scroll-down a {
  color: var(--white);
  display: block;
  width: 30px;
  height: 50px;
  text-align: center;
}
.banner-scroll-down a span {
  display: block;
  width: 17px;
  height: 17px;
  border-bottom: 3px solid var(--white);
  border-right: 3px solid var(--white);
  transform: rotate(45deg);
  margin: -10px 0 0 8px;
  animation: animate 2s infinite;
}
.banner-scroll-down a span:nth-child(2) {
  animation-delay: -0.2s;
}
.banner-scroll-down a span:nth-child(3) {
  animation-delay: -0.4s;
}
@keyframes animate {
  0% {
    opacity: 0;
    transform: rotate(45deg) translate(-15px, -15px);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: rotate(45deg) translate(15px, 15px);
  }
}
</style>
